import React from "react"

import { PlaceholderProvider } from '../components/placeholder'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Cards from "../components/cards/cards"
import Video from "../components/video/video"
import bgVideo from '../components/video/01_THS_Animatic_HSBC_Live-IPTV_Compressed.mp4'
import videoPoster from "../components/video/video-poster-iptv-hsbc.jpg"

const LiveIptvHsbc = () => (
  <PlaceholderProvider value={`hsbc`}>
    <Layout>
      <SEO title="IPTV HSBC" />
      <div className="cardsContainer cardsContainer--live-iptv cardsContainer--hsbc">
          <Cards postCount={5} hero={true} />
          <Video src={bgVideo} poster={videoPoster} />
      </div>
    </Layout>
  </PlaceholderProvider>
)

export default LiveIptvHsbc
